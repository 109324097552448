// Dependencies
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Countdown, { zeroPad } from 'react-countdown';
import Moment from 'moment';
import {CopyToClipboard} from 'react-copy-to-clipboard';
// import MiddleEllipsis from "react-middle-ellipsis";
import { useParams } from 'react-router-dom';

import MainLogo from './assets/img/logo.png';
import MenuIcon from './assets/img/menu.png';
import UserIcon from './assets/img/user-icon.png';

import LoginHeader from './assets/img/login-header-text.png';
import LoginButton from './assets/img/login-button.png';
import LoginGooGoo from './assets/img/googoo-welcome.png';

import CheckInHeader from './assets/img/checkin.png';
import CheckInGooGoo from './assets/img/googoo-checkin.png';

import CheckInBtn1 from './assets/img/checkinBtn1.png';
import CheckInBtn2 from './assets/img/checkinBtn2.png';
import CheckInBtn3 from './assets/img/checkinBtn3.png';
import Checked from './assets/img/checked.png';

import ClaimBtn from './assets/img/claim.png';

import BoosterIcon from './assets/img/booster.png';
import ArrowDownBtn from './assets/img/arrow-down.png';

import ReferralHeader from './assets/img/referral-header.png';
import CopyBtn from './assets/img/copy.png';
import ShareBtn from './assets/img/share.png';

import SmallCashSound from './assets/sound/cash-register-small.mp3';
import LoudCashSound from './assets/sound/cash-register-loud.mp3';

// Hooks
import { useClaim } from './hooks/useClaim';


function App() {

  const [copied, setCopied] = useState(false);
	const [copyText, setCopyText] = useState("");
	const copyReferral = () => {    
	  setCopied(true);
	  setCopyText("Copied");
	  setTimeout(() => setCopied(false), 3000);
	}

  const playSmallSFX = () => {
    new Audio(SmallCashSound).play();
  }

  const playLoudSFX = () => {
    new Audio(LoudCashSound).play();
  }

  const [referralCode, setReferralCode] = useState('');
  const handleLogin = () => {
    window.location.href = `https://gooodjob.xyz/api/auth/twitter?referral_code=${referralCode}`;
    // window.location.href = `http://localhost:8081/auth/twitter?referral_code=${referralCode}`;
  };

  const [userData, setUserData] = useState();
  const fetchUserData = () => {
    fetch("https://gooodjob.xyz/api/auth/current_user", {
    // fetch("http://localhost:8081/auth/current_user", {
      method: 'GET',
      credentials: 'include'
    })
    .then(response => response.json())
    .then(user => {
        if (user) {
          // User is logged in, handle accordingly
          console.log('Logged in user:', user);
          setUserData(user);
          getTotalPlayers();
        } else {
          console.log('No user logged in');
        }
    })
    .catch(error => console.error('Error fetching user:', error));
  };

  const [totalPlayers, setTotalPlayers] = useState();
  const getTotalPlayers = async () => {
		const response = await fetch('https://gooodjob.xyz/api/user/total');
		const json = await response.json();
		if (response.ok) {
			setTotalPlayers(json);
		}
	}

  const [leaderboardData, setLeaderboardData] = useState();
  const fetchLeaderboard = async () => {
    if (!userData) {
      fetchUserData();
    }
		const response = await fetch('https://gooodjob.xyz/api/user/leaderboard/'+userData._id);
		const json = await response.json();
		if (response.ok) {
      console.log("Top 20 players:", json);
			setLeaderboardData(json);
		}
	}

  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);

  const [referralPage, setReferralPage] = useState(false);

  // const { referrerUsername }  = useParams();

  const randomPoints = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const { claimRewardPoints, error, isLoading } = useClaim();

  const [pointsEarned, setPointsEarned] = useState(0);
  const [boostedPoints, setBoostedPoints] = useState(0);
  const [checkedIn, setCheckedIn] = useState(false);
  const handleClaimPoints = async () => {
    fetchUserData();
    if (!userData) {
      return;
    }
    setCheckedIn(true);
    var pts = randomPoints(1,99);
    setPointsEarned(pts);
    if (userData.boosters > 0) {
      setBoostedPoints(pts*2);
      await claimRewardPoints(userData._id, userData.points, pts, userData.boosters, true);
    } else {
      setBoostedPoints(pts)
      await claimRewardPoints(userData._id, userData.points, pts, userData.boosters, false);
    }
    fetchUserData();
	};

  const missionCountdown = ({ hours, minutes, seconds, completed }) => {
    if (!completed) {
      return (
        <>
          <div className="col-12 mt-3 text-center timer-padding mb-3">
            <h6 className="short-text text-white mb-3" style={{fontSize:"10px"}}>CHECK-IN AVAILABLE IN...</h6>
            <h3 className="countdown-timer text-white"><span>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span></h3>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="col-12 text-center">
            <h6 className="short-text text-white mb-3" style={{fontSize:"10px"}}>HELP GOOOGOOO, CLICK & EARN POINTS.</h6>
            {!checkedIn ? (
              <div className="row checkin-btn-cont-padding">
                <div className="col-4 text-center px-0">
                  <button className="btn checkin-btn p-0" onClick={(e) => {
                    setTimeout(() => setChecked1(true), 500);
                    setTimeout(() => playSmallSFX(), 700);
                  }}>
                    <img className="w-100" src={CheckInBtn1}></img>
                  </button>
                  {checked1 ? <img className="checked-tick" src={Checked}></img> : <></>}
                </div>
                <div className="col-4 text-center px-0">
                  <button disabled={!checked1} className="btn checkin-btn p-0" onClick={(e) => {
                    setTimeout(() => setChecked2(true), 500);
                    setTimeout(() => playSmallSFX(), 700);
                  }}>
                    <img className="w-100" src={CheckInBtn2}></img>
                  </button>
                  {checked2 ? <img className="checked-tick" src={Checked}></img> : <></>}
                </div>
                <div className="col-4 text-center px-0">
                  <button disabled={!checked2} className="btn checkin-btn p-0" data-bs-toggle="modal" data-bs-target="#claimModal" onClick={(e) => {
                    // handleClaimPoints();
                    setChecked3(true);
                    setTimeout(() => handleClaimPoints(), 800);
                    setTimeout(() => playLoudSFX(), 1000);
                  }}>
                    <img className="w-100" src={CheckInBtn3}></img>
                  </button>
                  {checked3 ? <img className="checked-tick" src={Checked}></img> : <></>}
                </div>
              </div>
            ):(
              <h5 className="text-white">Check-in Success!</h5>
            )}

          </div>
        </>
      );
    }
  };

  useEffect(() => {
    fetchUserData();
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('referral_code');
    setReferralCode(code);
  }, []);

  return (
    <Router>
        <div className="container game-container">
          <div className="row">
            <div className="col-12 px-0">
              <div className="game-bg pb-5">

                <div className="row header-section">
                  <div className="col-6">
                    <button className="btn p-0" onClick={(e) => {
                      fetchUserData();
                      setReferralPage(false);
                    }}>
                      <img className="header-logo" src={MainLogo} alt="Logo"></img>
                    </button>
                  </div>
                  <div className="col-6 text-right">
                    {userData ? (
                      <button className="btn p-0" onClick={(e) => {
                        fetchUserData();
                        fetchLeaderboard();
                        setReferralPage(true);
                      }}>
                        <div style={{marginTop:"5px"}}>
                          <img className="header-profile mr-1" src={userData && userData.profileImageUrl} alt="User Profile"></img>
                          <span className="text-white header-username mr-1">@{userData && userData.username}</span>
                        </div>
                      </button>
                    ):(
                      <>
                        <button className="btn p-0 mr-3" onClick={(e) => {
                          window.open('https://x.com/Gooodjob_io', '_blank');
                        }}>
                          <span className="brand-yellow" style={{fontSize:"28px"}}><i className="fa-brands fa-twitter"></i></span>
                        </button>
                        <button className="btn p-0 mr-3" onClick={(e) => {
                          window.open('https://www.instagram.com/Gooodjob.io/', '_blank');
                        }}>
                          <span className="brand-yellow" style={{fontSize:"28px"}}><i className="fa-brands fa-instagram"></i></span>
                        </button>
                        {/* <button className="btn p-0" onClick={(e) => {
                          window.open('https://x.com/Gooodjob_io', '_blank');
                        }}>
                          <span className="brand-yellow" style={{fontSize:"28px"}}><i className="fa-brands fa-telegram"></i></span>
                        </button> */}
                      </>
                    )}
                  </div>
                </div>

                {!userData ? (
                  <div className="row">
                    <div className="col-12 mt-3 text-center">
                      <img className="login-header" src={LoginHeader} alt="Login Text"></img>
                      <h3 className="login-sub-heading mt-2">GOOOGOOO PAY YOU!</h3>
                    </div>

                    <div className="col-12 mt-3 text-center">
                      <button className="btn login-button p-0" onClick={(e) => {
                        handleLogin();
                      }}>
                        <img className="w-100" src={LoginButton}></img>
                      </button>
                    </div>

                    <div className="col-12 mt-5 mt-sm-3">
                      <img className="login-googoo" src={LoginGooGoo}></img>
                    </div>
                  </div>
                ):(
                  <>
                    {!referralPage ? (
                      <>
                        <div className="row">
                          <div className="col-12 text-center">
                            <img className="checkin-header" src={CheckInHeader} alt="Check-in"></img>
                            <img className="checkin-googoo" src={CheckInGooGoo}></img>
                          </div>

                          {/* Countdown: 8 hours = 28800 s */}
                          <Countdown date={(Moment(userData.lastClaim).unix()+28800)*1000} renderer={missionCountdown}/>

                          {/* Claim Modal */}
                          <div class="modal fade" id="claimModal" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="claimModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered">
                              <div class="modal-content claim-modal py-3">
                                <div class="modal-body text-center px-2">
                                  <h5 className="text-white mb-0" style={{fontFamily:"Moon", fontSize:"16px"}}>YOU HAVE</h5>
                                  <h2 className="text-white mt-2 mb-0">EARNED</h2>
                                  <h1 className="brand-yellow earned-points"><span className="profit-green">+{boostedPoints}</span><span className="earned-points-text text-white"> PTS</span></h1>
                                  <h6 className="booster-points-text text-gray mt-3 mb-0">
                                    {userData && boostedPoints > pointsEarned ? (
                                      <>
                                        <span className="brand-yellow final-points">{pointsEarned}</span>
                                        <span className="earned-points-text text-white"> PTS</span>
                                      </>
                                    ):(
                                      <></>
                                    )}
                                    <br></br>
                                    {userData && boostedPoints == pointsEarned ? "(NO BOOSTER)" : "(BOOSTER X2)"}
                                  </h6>
                                  <button className="btn claim-btn mt-5 p-0" data-bs-dismiss="modal" onClick={(e) => {
                                    // fetchUserData();
                                    window.location.reload();
                                  }}>
                                    <img className="w-100" src={ClaimBtn}></img>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* Get Boosters */}
                          <div className="col-12 mt-3 text-center px-5">
                            <div className="booster-container">
                              <div className="row">
                                <div className="col-5 text-center px-0">
                                  <img className="booster-img" src={BoosterIcon}></img>
                                </div>
                                <div className="col-5 text-center pt-3 px-0">
                                  <h4 className="booster-counter text-white">{userData && userData.boosters}</h4>
                                  <h6 className="booster-left text-white">BOOSTERS LEFT<br></br>(POINTS MULTIPLIER)</h6>
                                </div>
                              </div>
                            </div>
                            <button className="btn mt-3 p-0" onClick={(e) => {
                              fetchUserData();
                              fetchLeaderboard();
                              setReferralPage(true);
                            }}>
                              <h5 className="text-white mb-0">GET BOOSTERS</h5>
                              <img className="get-booster-btn mb-3" src={ArrowDownBtn}></img>
                            </button>
                          </div>

                          <div className="col-12 text-center mt-3">
                            <h4 className="text-white" style={{fontFamily:"Moon", fontSize:"16px"}}>FOLLOW GOOOGOOO NOW!</h4>
                            <button className="btn p-0 mr-3" onClick={(e) => {
                              window.open('https://x.com/Gooodjob_io', '_blank');
                            }}>
                              <span className="text-white" style={{fontSize:"32px"}}><i className="fa-brands fa-twitter"></i></span>
                            </button>
                            <button className="btn p-0 mr-3" onClick={(e) => {
                              window.open('https://www.instagram.com/Gooodjob.io/', '_blank');
                            }}>
                              <span className="text-white" style={{fontSize:"32px"}}><i className="fa-brands fa-instagram"></i></span>
                            </button>
                          </div>

                        </div>
                      </>
                    ):(
                      <>
                        <div className="row">

                          {/* Referral Link */}
                          <div className="col-12 mt-3 text-center">
                            <img className="referral-heading" src={ReferralHeader}></img>
                          </div>
                          <div className="col-12 d-flex justify-content-center mt-2 text-center">
                            <div className="referral-link-box mx-1">
                              <p className="referral-link mb-0">@{userData && userData.username}</p>
                            </div>
                            <CopyToClipboard 
                              text={"https://gooodjob.xyz?referral_code="+userData.username}
                              onCopy={() => copyReferral()}>
                              <button className="btn p-0 mx-1" onClick={(e) => {
                              }}>
                                {!copied ? (
                                  <img className="referral-btn" src={CopyBtn}></img>
                                ):(
                                  <img className="referral-btn mr-2" src={Checked}></img>
                                )}
                              </button>
                            </CopyToClipboard>

                            {/* <button className="btn p-0 mx-1" onClick={(e) => {
                            }}>
                              <img className="referral-btn" src={ShareBtn}></img>
                            </button> */}
                          </div>
                          
                          {/* User Ranking */}
                          <div className="col-12 mt-3 text-center ranking-box-padding">
                            <div className="user-container">
                              <div className="row">
                                <div className="col-4 text-center px-0">
                                  <img className="user-img" src={userData && userData.profileImageUrl}></img>
                                  <p className="user-name brand-yellow mb-0">@{userData && userData.username}</p>
                                </div>
                                <div className="col-4 text-center pt-2 pl-1 pr-2">
                                  <div className="ranking-box">
                                    <h6 className="brand-yellow ranking-header mb-0">Ranking:</h6>
                                    <h6 className="text-white ranking-details mb-0">{leaderboardData && leaderboardData.currentUser.rank}</h6>
                                  </div>
                                </div>
                                <div className="col-4 text-center pt-2 pl-1 pr-2">
                                  <div className="ranking-box">
                                    <h6 className="brand-yellow ranking-header mb-0">GJ Points:</h6>
                                    <h6 className="text-white ranking-details mb-0">{userData && userData.points}</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          
                          {/* Leaderboard */}
                          <div className="col-12 mt-5 ranking-box-padding">
                            <h4 className="text-white text-center mb-0">LEADERBOARD</h4>
                            <h6 className="brand-yellow text-center mb-2">TOTAL PLAYERS: <span className="text-white">{totalPlayers}</span></h6>
                            {leaderboardData ? (
                              <>
                                <div className="leaderboard-container">
                                  <table className="table borderless">
                                    <thead>
                                      <tr>
                                        <th scope="col" className="round-border-top-left py-2"><p className="leaderboard-header mb-0">Ranking</p></th>
                                        <th scope="col" className="py-2"><p className="leaderboard-header mb-0">Username</p></th>
                                        <th scope="col" className="py-2"><p className="leaderboard-header mb-0">GJ Points</p></th>
                                        <th scope="col" className="round-border-top-right py-2"><p className="leaderboard-header mb-0">Referrals</p></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {leaderboardData && leaderboardData.leaderboard.map((user, index) => (
                                        <tr key={index}>
                                          <td className="py-2"><p className="text-white mb-0" style={{fontSize:"14px"}}>{index+1}</p></td>
                                          <td className="py-2">
                                            <span className="leaderboard-data text-white mb-0">
                                              <img className="header-profile mr-1" src={user.profileImageUrl} alt="User Profile"></img>
                                              @{user.username}
                                            </span>
                                          </td>
                                          <td className="py-2"><p className="text-white mb-0" style={{fontSize:"12px"}}>{user.points}</p></td>
                                          <td className="py-2"><p className="text-white mb-0" style={{fontSize:"12px"}}>{user.referral_points ? user.referral_points : 0}</p></td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </>
                            ):(
                              <></>
                            )}
                          </div>
                          
                          {/* Back to game */}
                          <div className="col-12 mt-3 text-center px-5">
                            <button className="btn mt-3 p-0" onClick={(e) => {
                              setReferralPage(false);
                            }}>
                              <h5 className="text-white mb-0">BACK TO GAME</h5>
                              <img className="get-booster-btn mb-3" src={ArrowDownBtn}></img>
                            </button>
                          </div>

                          <div className="col-12 text-center mt-3">
                            <h4 className="text-white" style={{fontFamily:"Moon", fontSize:"16px"}}>FOLLOW GOOOGOOO NOW!</h4>
                            <button className="btn p-0 mr-3" onClick={(e) => {
                              window.open('https://x.com/Gooodjob_io', '_blank');
                            }}>
                              <span className="text-white" style={{fontSize:"32px"}}><i className="fa-brands fa-twitter"></i></span>
                            </button>
                            <button className="btn p-0 mr-3" onClick={(e) => {
                              window.open('https://www.instagram.com/Gooodjob.io/', '_blank');
                            }}>
                              <span className="text-white" style={{fontSize:"32px"}}><i className="fa-brands fa-instagram"></i></span>
                            </button>
                          </div>

                        </div>
                      </>
                    )}
                  </>
                )}



              </div>
            </div>
          </div>
        </div>
    </Router>
  );
}

export default App;