import { useState } from 'react';

export const useClaim = () => {

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    const claimRewardPoints = async (curUserId, curPoints, pointsEarned, curBoosters, boosterStatus) => {
        setIsLoading(true);
        setError(null);

        const now = new Date();
        var deductBooster = 0;
        var finalPointsEarned = 0;

        if (boosterStatus) {
            deductBooster = 1;
            finalPointsEarned = pointsEarned*2;
            console.log("Points Increment:",finalPointsEarned);
        } else {
            finalPointsEarned = pointsEarned;
        }

        const response = await fetch('https://gooodjob.xyz/api/user/'+curUserId, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify({
                points: Number(curPoints)+Number(finalPointsEarned),
                boosters: Number(curBoosters)-Number(deductBooster),
                lastClaim: now
            })
        })
        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
            setError(json.error);
        }
        if (response.ok) {
            setIsLoading(false);
        }
        
    }

    return { claimRewardPoints, isLoading, error };
}